/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/

import { Schema } from "rsuite";
import { useState, useEffect } from "react";
import {
  Widgets,
  httpClient,
  FormAllInOne,
  Message,
  Notification,
  Toaster,
} from "@tol/tol-ui";
import { LoadingContent } from "../Components";

interface UserAttributes {
  name: string;
  emailAddress: string;
  ORCID: string;
  publicationName: string;
  primaryAffiliation: string;
  affiliationCity: string;
  secondaryAffiliation: string;
  careerStatus: string;
  nationality: string;
}

interface ProfileProps {
  onSubmitSuccess: () => void;
}

type Appearance = "default" | "primary" | "link" | "subtle" | "ghost";
type Color = "red" | "orange" | "yellow" | "green" | "cyan" | "blue" | "violet";
type ButtonType = "button" | "submit" | "reset" | undefined;

const CAREER_STATUS = [
  "Student",
  "Post-doc",
  "ECR Group Leader",
  "Senior Group Leader",
  "Non-academic Researcher",
  "Other",
];

const GENERIC_ERROR_MESSAGE =
  "Sorry an error has occurred, please reload your page and try again.";
const T_AND_CS_ERROR =
  "Cannot submit, not all terms and conditions have been accepted.";
const SUCCESS_MESSAGE = "Profile updated successfully.";
const REQUIRED_FIELD = "This field is required";

const { StringType } = Schema.Types;

const model = Schema.Model({
  name: StringType().isRequired(REQUIRED_FIELD),
  emailAddress: StringType()
    .isEmail("Please enter a valid email address")
    .isRequired(REQUIRED_FIELD),
});

const codeOfConduct = (
  <span>
    <p>
      I agree to the
      <a href="https://docs.google.com/document/d/1Mzyio-2NLZyrNH9J5pwOKtM2FuDFPL_QNGU31NrbZfQ/edit#heading=h.j2ubdz5q2r0u">
        &nbsp;privacy policy
      </a>
      .
    </p>
  </span>
);

const privacyPolicy = (
  <span>
    <p>
      I agree to abide by the
      <a href="https://docs.google.com/document/d/1Mzyio-2NLZyrNH9J5pwOKtM2FuDFPL_QNGU31NrbZfQ/edit#heading=h.j2ubdz5q2r0u">
        &nbsp;code of conduct
      </a>
      .
    </p>
  </span>
);

const consentStatement = (
  <span>
    <p>
      I consent with my personal data being processed as described in the
      <a href="https://docs.google.com/document/d/1sG0rDRIST6JfPaSH1-6J1A9uXn84Jv7heR6qlGhG8T4/edit#heading=h.n307wsht1abf">
        &nbsp;consent statement
      </a>
      .
    </p>
  </span>
);

const profileFormConfig = (
  termsAccepted: boolean,
  hasUnsavedChanges: boolean
) => ({
  fields: [
    {
      name: "name",
      type: "text",
      label: "Name:",
      placeholder: "Name",
    },
    {
      name: "emailAddress",
      type: "email",
      label: "Email Address:",
      placeholder: "Email Address",
    },
    {
      name: "ORCID",
      label: "ORCID:",
      type: "text",
      helpText: "This field is automatically populated and cannot be changed.",
      placeholder: "ORCID",
      readOnly: true,
      centered: true,
    },
    {
      name: "publicationName",
      type: "text",
      label: "Name on Publications (If Different to Given / Family Name):",
      placeholder: "Name on Publications (optional)",
    },
    {
      name: "primaryAffiliation",
      type: "text",
      label: "Primary Workplace / Institution Affiliation:",
      placeholder: "Primary workplace/institution",
    },
    {
      name: "affiliationCity",
      type: "text",
      label: "City / country of primary affiliation:",
      placeholder: "Primary city/country",
    },
    {
      name: "secondaryAffiliation",
      type: "text",
      label: "Secondary Workplace / Institutional Affiliation:",
      placeholder: "secondary workplace/institution (optional)",
    },
    {
      name: "nationality",
      type: "countrySelect",
    },
    {
      name: "careerStatus",
      type: "singleselectcustomoption",
      label: "Career Status:",
      block: true,
      customOptionPlaceholder: "Please enter your career status:",
      data: CAREER_STATUS,
    },
    {
      id: "termsAndConditions",
      name: "termsAndConditions",
      label: "Please agree to our terms and conditions before submitting:",
      type: "checkbox",
      hidden: termsAccepted,
      checkboxConfig: {
        fields: [
          {
            value: "Code of Conduct",
            children: codeOfConduct,
          },
          {
            value: "Privacy Policy",
            children: privacyPolicy,
          },
          {
            value: "Consent Statement",
            children: consentStatement,
          },
        ],
      },
    },
  ],
  buttonConfig: {
    buttons: [
      {
        name: "save",
        text: "Save Profile",
        appearance: "primary" as Appearance,
        disabled: !hasUnsavedChanges,
        color: "green" as Color,
        type: "submit" as ButtonType,
        onClick: () => null
      },
    ],
    buttonStyle: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
    },
  },
});

function Profile(props: ProfileProps) {

  const {onSubmitSuccess} = props;

  const [isLoading, setIsLoading] = useState(true);
  const [isNewProfile, setIsNewProfile] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [formUnsavedChanges, setFormUnsavedChanges] = useState(false);
  const [, setValid] = useState(false);
  const toaster = Toaster();
  const [profile, setProfile] = useState<UserAttributes>({
    name: "",
    emailAddress: "",
    ORCID: "",
    publicationName: "",
    primaryAffiliation: "",
    affiliationCity: "",
    secondaryAffiliation: "",
    careerStatus: "",
    nationality: "",
  });

  useEffect(() => {
    loadUserData();
  }, []);

  const pushSuccessNotification = () => {
    toaster.push(
      <Notification
        type="success"
        children={SUCCESS_MESSAGE}
        header="Success"
        closable
      />,
      { placement: "topEnd", duration: 4000 }
    );
  };

  const pushErrorMessage = (message: string) => {
    toaster.push(
      <Message
        type="error"
        children={message}
        closable
        showIcon={true}
      />,
      { duration: 4000 }
    );
  };

  const loadUserData = async () => {
    setIsLoading(true);

    try {
      const userId = getUserId();
      const res: any = await httpClient().get("/data/user", {
        params: {
          filter: JSON.stringify({ and_: { id: { eq: { value: userId } } } }),
        },
      });

      if (res.status === 200 && res.data.data.length > 0) {
        const data: any = res.data.data[0].attributes;
        setProfileData(data);

        const isNew = !data.name && !data.email;
        setIsNewProfile(isNew);

        setTermsAccepted(data.terms_agreed || false);
      }
    } catch (error) {
      pushErrorMessage(GENERIC_ERROR_MESSAGE);
    } finally {
      setIsLoading(false);
    }
  };

  const setProfileData = (data: any) => {
    setProfile({
      name: data.name || "",
      emailAddress: data.email || "",
      ORCID: data.oidc_id,
      publicationName: data.publication_name || "",
      primaryAffiliation: data.organisation || "",
      affiliationCity: data.affiliation_city || "",
      secondaryAffiliation: data.secondary_affiliation || "",
      careerStatus: data.career_status || "",
      nationality: data.nationality || "",
    });
  };

  const getUserId = () => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    return user.id;
  };

  const submitFormData = async (formData: any) => {
    try {
      const userId = getUserId();
      const res = await httpClient().post("/data/user:upsert", {
        data: [
          {
            id: userId,
            type: "user",
            attributes: {
              name: formData.name,
              email: formData.emailAddress,
              oidc_id: formData.ORCID,
              publication_name: formData.publicationName,
              organisation: formData.primaryAffiliation,
              affiliation_city: formData.affiliationCity,
              secondary_affiliation: formData.secondaryAffiliation,
              career_status: formData.careerStatus,
              nationality: formData.nationality,
              terms_agreed:
                termsAccepted || formData.termsAndConditions.length === 3,
            },
          },
        ],
      });
      if (res.status === 200) {
        pushSuccessNotification();
        setTermsAccepted(true);
        setIsNewProfile(false);
        onSubmitSuccess();
      }
    } catch (error) {
      pushErrorMessage(GENERIC_ERROR_MESSAGE);
    }
  };

  const handleSubmit = (formData: any, isValid: boolean) => {
    if (formData.termsAndConditions.length !== 3 && !termsAccepted) {
      pushErrorMessage(T_AND_CS_ERROR);
    }

    if (
      isValid &&
      (formData.termsAndConditions.length === 3 || termsAccepted)
    ) {
      submitFormData(formData);
    }
  };

  const profileForm = (
    <div className="profile-form-container">
      <h3 className="profile-header">
        {isNewProfile ? "Create Your Profile" : "Update Your Profile"}
      </h3>
      <p>
        Please update your profile. If you provide an email, you can
        subscribe/unsubscribe to mailing lists.
      </p>
      <FormAllInOne
        formConfig={profileFormConfig(termsAccepted, formUnsavedChanges)}
        onValidate={(isValid: boolean) => setValid(isValid)}
        fluid={true}
        initialData={profile}
        model={model}
        onUnsavedChanges={(hasChanges: boolean) =>
          setFormUnsavedChanges(hasChanges)
        }
        onSubmit={(formData: any, isValid: boolean) => {
          handleSubmit(formData, isValid);
        }}
      />
    </div>
  );

  const components = isLoading
    ? [
        {
          component: <LoadingContent wording="Getting profile..." />,
          type: "full",
        },
      ]
    : [
        {
          component: profileForm,
          type: "full",
        },
      ];

  return <Widgets components={components} />;
}

export default Profile;
