/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/

import { Widgets, RemoteTable, useZone } from "@tol/tol-ui";

function Admin() {
  const zone = useZone({
    endpoint: "user_mailing_list",
    baseUrl: "api/v1/data",
    components: [{ id: "admin-table-v2" }],
  });

  const adminTable = (
    <RemoteTable
      id="admin-table-v2"
      defaultSort="mailing_list.name"
      noFilter
      noSorting
      fields={{
        "mailing_list.name": {
          rename: "Mailing List",
        },
        "user.email": {
          rename: "User Email",
        },
        "mailing_list.id": {
          rename: "Mailing List ID",
          cellRenderer: null,
          width: 150,
          type: "int",
          hidden: true,
        },
        "mailing_list.description": {
          rename: "Mailing List Description",
          hidden: true
        },
        "mailing_list.mailing_list_category_id": {
          rename: "Mailing List Category ID",
          hidden: true
        },
      }}
      {...zone}
    />
  );

  const title = (
    <>
      <h2 style={{ marginBottom: "12px" }}>Admin Dashboard</h2>
      <p>
        Here you can sort the information to view each mailing list and export
        to CSV.
      </p>
    </>
  );

  const components = [
    {
      component: title,
      type: "full",
    },
    {
      component: adminTable,
      type: "xl",
    },
  ];

  return (
    <div>
      <Widgets components={components} />
    </div>
  );
}

export default Admin;
