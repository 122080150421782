/*
SPDX-FileCopyrightText: 2023 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/

import ReactDOM from 'react-dom';
import { Home, Database, Admin, CombinedProfile } from './pages';
import reportWebVitals from './reportWebVitals';
import { TolApp, Page } from '@tol/tol-ui';
import './scss/styling.scss';


const database: Page = {
  name: "Database",
  element: <Database />
}

const profile: Page = {
  name: "Profile",
  element: <CombinedProfile />,
  auth: true,
}

const admin: Page = {
  name: "Admin",
  element: <Admin />,
  auth: ["admin"]
}

ReactDOM.render(
  <TolApp
    brand='Tree of Sex'
    homePage={<Home />}
    pages={[
      database,
      profile,
      admin
    ]}
    register={true}
    customCallbackUrl="/profile"
  />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
