/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/

import { Widgets, httpClient } from "@tol/tol-ui";
import { CheckPicker } from "rsuite";
import { useEffect, useState } from "react";
import { LoadingContent } from "../Components";

interface MailingList {
  data: {
    [id: string]: {
      data?: {
        [id: string]: {
          mailingListName: string;
          userMailingListId?: number;
        };
      };
      order?: string[];
    };
  };
  order: string[];
}

function Mailing() {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const user = localStorage.getItem("user") || "{}";
  const userId = JSON.parse(user)["id"] || null;

  const [mailingList, setMailingList] = useState<MailingList>({
    data: {},
    order: [],
  });
  const selectedMailingLists = (category: string) => {
    const selected = [];
    const mailingListData = mailingList.data[category].data! || null;
    if (mailingListData) {
      for (const id of Object.keys(mailingListData)) {
        if ("userMailingListId" in mailingListData[id]) {
          selected.push(id);
        }
      }
    }
    return selected;
  };

  const retreieveUser = async () => {
    return await httpClient()
      .get("/data/user", {
        params: {
          filter: { and_: { id: { eq: { value: userId } } } },
        },
      })
      .then((res: any) => {
        const attributes = res.data.data[0].attributes;
        setName(attributes.name);
        setEmail(attributes.email);
      })
      .catch((e: any) => {
        setLoading(false);
        console.error(e.message);
        setError(e.message);
      });
  };

  const retrieveMailingListCategories = async () => {
    return await httpClient()
      .get("/data/mailing_list_category", {})
      .then((res: any) => {
        res.data.data.map((item: any) => {
          mailingList.data[item.id] = {};
          mailingList.order.push(item.id);
        });
        mailingList.order.sort();
      })
      .catch((e: any) => {
        console.error(e.message);
        setError(e.message);
      });
  };

  const retrieveMailinglists = async (categories: string[]) => {
    const promises = categories.map((category) =>
      httpClient()
        .get("/data/mailing_list", {
          params: {
            filter: {
              and_: { mailing_list_category_name: { eq: { value: category } } },
            },
          },
        })
        .then((res: any) => {
          // initialize mailing list data/order
          mailingList.data[category].data = {};
          mailingList.data[category].order = [];
          res.data.data.map((item: any) => {
            mailingList.data[category].data![item.id] = {
              mailingListName: item.attributes.name,
            };
            mailingList.data[category].order?.push(item.id);
          });
          mailingList.data[category].order?.sort();
        })
        .catch((e: any) => {
          console.error(e.message);
          setError(e.message);
        })
    );
    return await Promise.all(promises);
  };

  const retrieveUserMailingLists = async () => {
    return await httpClient()
      .get("/data/user_mailing_list", {
        params: {
          filter: { and_: { "user.id": { eq: { value: userId } } } },
        },
      })
      .then((res: any) => {
        for (const category of mailingList.order) {
          res.data.data.map((item: any) => {
            const mailingListId = item.relationships.mailing_list.data.id;
            const userMailingListId = item.id;
            if (mailingListId in mailingList.data[category].data!) {
              mailingList.data[category].data![
                mailingListId
              ].userMailingListId = userMailingListId;
            }
          });
        }
      })
      .catch((e: any) => {
        console.error(e.message);
        setError(e.message);
      });
  };

  const fetchMailingListData = () => {
    retrieveMailingListCategories().then(() => {
      retrieveMailinglists(mailingList.order).then(() => {
        retrieveUserMailingLists()
          .then(() => {
            setMailingList({ ...mailingList });
          })
          .finally(() => {
            setLoading(false);
          });
      });
    });
  };

  useEffect(() => {
    retreieveUser().then(() => {
      fetchMailingListData();
    });
  }, []);

  const addMailingList = async (mailingListId: string) => {
    const upsertData = {
      data: [
        {
          type: "user_mailing_list",
          relationships: {
            mailing_list: {
              data: {
                type: "mailing_list",
                id: mailingListId,
              },
            },
            user: {
              data: {
                type: "user",
                id: userId,
              },
            },
          },
        },
      ],
    };
    return await httpClient()
      .post("/data/user_mailing_list:upsert", upsertData)
      .catch((e: any) => {
        console.error(e.message);
        setError(e.message);
      });
  };

  const deleteMailingList = async (userMailingListId: number) => {
    return await httpClient()
      .delete("/data/user_mailing_list/" + userMailingListId)
      .catch((e: any) => {
        console.error(e.message);
        setError(e.message);
      });
  };

  const onChangeValue = (category: string, value: any) => {
    const existing = selectedMailingLists(category);
    const toAdd = value.filter((v: any) => !existing.includes(v));
    const toRemove = existing.filter((v: any) => !value.includes(v));

    if (toRemove.length > 0) {
      for (const changeValue of toRemove) {
        const userMailingListId =
          mailingList.data[category].data![changeValue].userMailingListId;
        deleteMailingList(userMailingListId!).then(() => {
          delete mailingList.data[category].data![changeValue]
            .userMailingListId;
          setMailingList({ ...mailingList });
        });
      }
    } else if (toAdd.length > 0) {
      for (const changeValue of toAdd) {
        addMailingList(changeValue).then((res: any) => {
          if (res.status === 200) {
            retrieveUserMailingLists().then(() => {
              setMailingList({ ...mailingList });
            });
          }
        });
      }
    }
  };

  const data = (category: string) => {
    return mailingList.data[category].order!.map((id) => {
      return {
        label: mailingList.data[category].data![id].mailingListName,
        value: id,
      };
    });
  };

  if (error !== "") {
    return <p>{error}</p>;
  }

  if (loading) {
    return <LoadingContent wording="Checking profile..." />;
  }

  if (!email || !name) {
    return (
      <>
        <h3 className="mailing-list-warning">
          Please
          <a href="/profile"> create your profile </a>
          before subscribing to any mailing lists.
        </h3>
        <p className="mailing-list-warning">
          You will need to enter your name and email address before you can be
          put on to mailing lists.
        </p>
      </>
    );
  }

  const mailing = (
    <div>
      <h3 className="profile-header">Update Mailing List Preferences</h3>
      <p>
        Please update your mailing list preferences using the checkboxes below.
      </p>
      {mailingList.order.map((category: string, key: any) => {
        return (
          <div
            key={key}
            className="form-wrapper"
          >
            <h5>{category}</h5>
            <CheckPicker
              block
              placeholder={category + " Mailing Lists"}
              data={data(category)}
              value={selectedMailingLists(category)}
              onChange={(value: any) => {
                onChangeValue(category, value);
              }}
            />
            <br />
          </div>
        );
      })}
      <p style={{ color: "grey" }}>
        Please note: Your preferences will save automatically when
        selecting/deselecting.
      </p>
    </div>
  );

  const components = [
    {
      component: mailing,
      type: "full",
    },
  ];

  return <Widgets components={components} />;
}

export default Mailing;
