/*
SPDX-FileCopyrightText: 2024 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/
import { useState } from "react";
import { Tabs } from "rsuite";
import { Widgets } from "@tol/tol-ui";
import { Profile, Mailing } from "./index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEnvelope } from "@fortawesome/free-solid-svg-icons";

function CombinedProfile() {
const [refreshMailing, setRefreshMailing] = useState(false);

const handleProfileSubmit = () => {
  setRefreshMailing(prev => !prev);
}

  const tabs = (
    <Tabs
      defaultActiveKey="1"
    >
      <Tabs.Tab
        eventKey="1"
        title="Profile"
        icon={<FontAwesomeIcon icon={faUser} />}
      >
        <Profile onSubmitSuccess={handleProfileSubmit} />
      </Tabs.Tab>
      <Tabs.Tab
        eventKey="2"
        title="Mailing Lists"
        icon={<FontAwesomeIcon icon={faEnvelope} />}
      >
        <Mailing key={refreshMailing as any} />
      </Tabs.Tab>
    </Tabs>
  );

  const components = [
    {
      component: tabs,
      type: "full",
    },
  ];

  return <Widgets components={components} />;
}

export default CombinedProfile;
